//====================================
//    Media Query Mixins
//====================================

// break() - Media query targets below a given breakpoint
@mixin break($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @if ($value != null) {
        $breakpoint: $value;
    }

    @if (type-of($breakpoint) == 'number') {
        @media screen and (max-width: #{$breakpoint}) {
            @content;
        }
    } @else if ($breakpoint == 'retina') {
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            @content;
        }
    } @else {
        @media #{$breakpoint} {
            @content;
        }
    }
}

// break-min() - Media query targets above a given breakpoint
@mixin break-min($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @if ($value != null) {
        $breakpoint: $value;
    }

    @if (type-of($breakpoint) == 'number') {
        @media screen and (min-width: #{$breakpoint}) {
            @content;
        }
    } @else {
        @media #{$breakpoint} {
            @content;
        }
    }
}

// break-between() - Media query targets screen-widths between two breakpoints
@mixin break-between($smallbp, $largebp) {
    $smallbpvalue: map-get($breakpoints, $smallbp);
    $largebpvalue: map-get($breakpoints, $largebp);

    @if ($smallbpvalue != null) {
        $smallbp: $smallbpvalue;
    }

    @if ($largebpvalue != null) {
        $largebp: $largebpvalue;
    }

    @media (max-width:#{$largebpvalue}) and (min-width:#{$smallbpvalue}) {
        @content;
    }
}

// break-mobile-landscape - Media query targets only mobile devices in landscape orientation
@mixin break-mobile-landscape {
    @media only screen
    and (min-device-width: 320px)
    and (max-device-width: 815px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape) {
        @content;
    }
}

// ie11fix - Media query targets only IE11
// Just put all your IE11 fixes within the content, nested media queries and @includes are supported
// Usage:  @include ie11fix { ... };
@mixin ie11fix {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// edgefix - Media query targets only MS EDGE
// Just put all your EDGE fixes within the content, nested media queries and @includes are supported
// Usage:  @include edgefix { ... };
@mixin edgefix {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

//====================================
//   Font Size Mixins
//====================================

// Convert px to rem
// Usage:  @include rem(16px);
@function rem($size) {
    @return $size / $base-font-size * 1rem;
}

// Responsive font-size, from https://css-tricks.com/snippets/sass/viewport-sized-typography-minimum-maximum-sizes/
// Usage:  @include responsive-font(3vw, 1rem);
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;

    @media (max-#{$dimension}: #{$min-breakpoint}) {
        font-size: $min;
    }

    @if $max {
        $max-breakpoint: $max / $responsive-unitless * 100;

        @media (min-#{$dimension}: #{$max-breakpoint}) {
            font-size: $max;
        }
    }

    @if $fallback {
        font-size: $fallback;
    }

    font-size: $responsive;
}
