//======================================
//  Colors
//======================================

// Neutrals
$white: #fff;
$smoke: #f8f8f8;
$gray-xlight: #efefef;
$gray-light: #9b9b9b;
$gray: #505050;
$gray-dark: #222;
$black: #000;

// Primary
$aqua: #51ecd0;
$blue: #039;
$blue-light: #005fd2;
$purple: #251c7d;
$green: #093;

// Gradients
%gradient-blue {
    background-image: linear-gradient(90deg, $blue 0%, $blue-light 100%);
}

%gradient-white {
    background-image: linear-gradient(0deg, $white 0%, rgba($white , 0) 100%);
}

%bg-gradient {
    //scss-lint:disable ColorVariable
    background-image: linear-gradient(90deg, rgba(#024289, 0.4) 0%, rgba(#53a0fd, 0.4) 47%, rgba($aqua, 0.4) 100%);
    position: relative;

    &::after {
        @extend %gradient-white;
        bottom: 0;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;
    }
}


//======================================
//    Fonts, weights
//======================================

$sans: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

$normal: 400;
$semibold: 600;
$bold: 700;

$base-font-size: 16px;


//======================================
//    Breakpoints
//======================================

$breakpoints: (
    'sm-mobile': 20em,        // 320px
    'mobile': 23.4375em,      // 375px
    'lg-mobile': 25em,        // 400px
    'phablet': 37.5em,        // 600px
    'sm-tablet': 46em,        // 736px
    'tablet-vert': 48em,      // 768px
    'lg-tablet': 50em,        // 800px
    'xs-desktop': 57.5em,     // 920px
    'sm-desktop': 62.5em,     // 1000px
    'tablet-horiz': 64em,     // 1024px
    'desktop': 75em,          // 1200px
    'md-desktop': 87.5em,     // 1400px
    'lg-desktop': 100em       // 1600px
);

$main-breakpoint: tablet-vert;          // Where the layout will all stack for mobile
$secondary-breakpoint: sm-desktop;      // Where narrow-width columns will stack before main breakpoint
