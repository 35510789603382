@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/buttons';

body {
    @extend %bg-gradient;
}

#login {
    position: relative;
    z-index: 2;
}

.login {
    h1 a {
        background-image: url('../images/logo-nru-blue.svg');
        background-size: contain;
        height: 120px;
        width: 240px;
    }

    #backtoblog {
        a {
            @extend %button-style;
            @extend %btn-white;
        }
    }
}
