/*======================================
    Buttons
======================================*/
%button-style {
    appearance: none;
    background: $blue;
    border: 1px solid $blue;
    border-radius: 0.25rem;
    box-shadow: 0;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $sans;
    font-size: rem(14px);
    font-style: normal;
    font-weight: $semibold;
    line-height: 1.5;
    margin: 0 1em 0.5em 0;
    padding: 0.4em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    transition: all 0.1s ease-out;
    white-space: nowrap;

    &:hover,
    &:focus {
        background-color: lighten($blue, 20%);
        box-shadow: 0 0 1rem rgba($blue, 0.3);
        transform: scale(1.025);
    }
}

%btn-white {
    background-color: $white;
    border-color: $blue;
    color: $blue;

    &:hover,
    &:focus {
        background-color: lighten($blue, 20%);
        color: $white;
    }
}

.btn {
    @extend %button-style;

    .centered & {
        margin: 0 0.5rem 0.5rem;
    }

    &.btn-small {
        font-size: rem(11px);
        font-weight: $semibold;
        padding: 0.6em 2em 0.4em;
    }

    &.btn-blue {
        background-color: $blue;
        border-color: $blue;
        color: $white;

        &:hover,
        &:focus {
            background-color: lighten($blue, 20%);
        }
    }

    &.btn-white {
        @extend %btn-white;
    }

    &[disable] {
        background-color: $gray-xlight;
        color: darken($gray-xlight, 20%);
        cursor: default;

        &:hover,
        &:focus {
            background-color: $gray-xlight;
        }
    }
}
